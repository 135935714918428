import React, { useState, useEffect } from 'react'
import Container from '../../layouts/Container/Container'
import Button from '../atoms/Button'

import {
  stQuiz
} from './../../utils/helpers'
import { connect } from 'react-redux'

function TestQuestions({
  token,
  context
}) {
  const [yesOrNot, setYesOrNot] = useState(false)
  const [response, setResponse] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [ok, serOk] = useState(false)

  const respond = (str) => {
    setYesOrNot(str)
  }
  const handleQuiz = () => {
    setIsLoading(true)
    console.log("Send quiz!", yesOrNot, response)

    stQuiz(token, yesOrNot, response).then((res) => {

      if (res && res.statusCode === 200) {
        setYesOrNot(false)
        setResponse("")
        serOk(true)
      }
      console.log("resres", res)
      setIsLoading(false)
    }).catch(() => {
      setIsLoading(false)
    })
  }

  const handleChange = async (e) => {
    setResponse(e.target.value)
  }

  useEffect(() => {
    if (context && context.quiz && context.quiz[0]) {
      serOk(true)
    }
  }, [context])

  return (
    <div className={`mc-test-questions`}>
      <Container>
        <h4>
          {ok
            ? '¡Gracias por tus comentarios!'
            : '¿Te pareció útil este contenido?'
          }
        </h4>
        {!ok &&
          <div className='mc-test-questions--buttons'>
            <button onClick={() =>respond('yes')}>Sí</button>
            <button onClick={() =>respond('no')}>No</button>
          </div>
        }
        <div className={`mc-test-questions--details slow_ani ${yesOrNot ? 'active' : ''} ${isLoading ? 'loading' : ''}`}>
          <p>
            {
              yesOrNot === 'yes'
                ? 'Cuéntanos, ¿qué otra información esperas encontrar?'
                : 'Cuéntanos, ¿cómo podemos mejorar?'
            }
          </p>
          <textarea onChange={handleChange} placeholder='Sugerencia...'></textarea>
          <Button
            type="primary"
            text="Enviar"
            loading={isLoading}
            status="active"
            onClick={handleQuiz}
          />
        </div>
      </Container>
    </div>
  )
}

const mapState = ({ user: {token} }) => {
  return {
    token
  }
}

export default connect(mapState, {})(TestQuestions)