import React, { useContext, useEffect, useRef, useState } from 'react';
import HeaderLanding from '../../layouts/Header/HeaderLanding'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { StateController } from './../../App';
import { Navigation, Pagination } from 'swiper/modules';
import Preloader from '../atoms/Preloader';
import { sendDataLayer } from '../../utils/functions';
import { Link } from 'react-router-dom';

const Onboarding = ({isLogin}) => {
    const { context } = useContext(StateController);
    const [slides, setSlides] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const titleRefs = useRef([]);

    useEffect(() => {
        if (window.location.pathname.includes("onboarding")) {
            document.body.classList.add("onboardin-page");
        } else {
            document.body.classList.remove("onboarding-page");
        }
    }, []);

    useEffect(() => {
        const loadSlides = () => {
            setTimeout(() => {
                const slidesSegment1 = [
                    {
                        num: "1.",
                        text: "Conoce tu meta de facturación y las categorías que te llevan a ganar premios.",
                        image: "/assets/images/img_card_1.png"
                    },
                    {
                        num: "2.",
                        text: "Usa tu Mastercard Debit Banco Guayaquil en todas tus compras para alcanzar las metas.",
                        image: "/assets/images/im_card_2_1.png"
                    },
                    {
                        num: "3.",
                        text: "Redime la Gift Card digital que más te guste y apúntale a ganar el premio mayor.",
                        image: "/assets/images/img_card_premios.png"
                    },
                    {
                        num: "4.",
                        title: "¡Gana un viaje a Río de Janeiro para dos!",
                        text: "Cada vez que cumplas tu meta estarás más cerca de ir a Brasil.",
                        image: "/assets/images/img_card_4_aj.png",
                        tyc: "*Aplican términos y condiciones."
                    }
                ];

                const slidesSegment2 = [
                    {
                        num: "1.",
                        text: "Las compras que haces con tu Mastercard Debit Banco Guayaquil te premian. ¡Una razón más para usarla!",
                        image: "/assets/images/inactive_img_card_1.png"
                    },
                    {
                        num: "2.",
                        text: "Conoce tu meta y realiza todas las compras con tu Tarjeta Mastercard Debit Banco Guayaquil para alcanzarla.",
                        image: "/assets/images/inactive_img_card_2.png"
                    },
                    {
                        num: "3.",
                        text: "¡Elige tu Gift Card favorita y redímela en las tiendas que más te gustan!",
                        image: "/assets/images/inactive_img_card_3.png"
                    },
                    {
                        num: "4.",
                        title: "¡Un kit de Apple podría ser tuyo!",
                        text: "Mientras más veces cumplas tu meta más oportunidades tienes de ganar un Macbook, iPhone y Apple Watch.",
                        image: "/assets/images/inactive_img_card_4.png",
                        tyc: "*Aplican términos y condiciones."
                    }
                ];

                setSlides(context.user.segment === '1' ? slidesSegment1 : slidesSegment2);
                setLoading(false);
            }, 1500);
        };

        if (context && context.user) {
            loadSlides();
        } else {
            setLoading(false);
        }
    }, [context]);

    useEffect(() => {        
        if (titleRefs.current.length === 0) {
          titleRefs.current = new Array(slides.length).fill(null);
        }
      }, [slides]);

    if (loading) {
        return <div>
            <Preloader
                type="section"
                invert
            />
        </div>;
    }

    const layerOnboarding = () => {
         
    }
    
    const handleSlideChange = (swiper) => {
        const activeTitle = titleRefs.current[activeIndex]?.innerText;
        console.log('Active slide title:', activeTitle);   
        setActiveIndex(swiper.activeIndex);
        sendDataLayer({ datalayer: ({ event: "onboarding", text: activeTitle, }),repeat: true })
    };

    const handleRedirectToProgreso = () => {
        const activeTitle = titleRefs.current[activeIndex]?.innerText;
        console.log('Active slide title:', activeTitle); 
        sendDataLayer({ datalayer: ({ event: "onboarding", text: activeTitle, }),repeat: true })
        window.location.href = '/GanaConMastercardDebit/progreso';
    };


      

    return (
        <>

            {
                context ?
                    <div className='mc-page onboarding'>
                       { !isLogin && <HeaderLanding />}
                        <Swiper
                            modules={[Navigation, Pagination]}
                            navigation={{
                                nextEl: '.swiper-button-next-custom',
                                prevEl: '.swiper-button-prev-custom'
                            }}
                            pagination={{ clickable: true }}
                            spaceBetween={50}
                            slidesPerView={1}
                            onSlideChange={handleSlideChange}
                        >
                            {slides.map((slide, index) => (
                                <SwiperSlide key={index}>
                                    <div className="slide-content">
                                        <div className="text">
                                            <div className='num-container'>
                                                <span>{slide.num}</span>
                                            </div>
                                            <div>
                                                <h3>{slide.title}</h3>
                                                <p
                                                    ref={(el) => (titleRefs.current[index] = el)}
                                                    data={slide.text}>{slide.text}</p>
                                            </div>
                                            <div className='tyc-container'>
                                                {slide.tyc && <Link to="/GanaConMastercardDebit/terminos-y-condiciones">{slide.tyc}</Link>}
                                            </div>
                                        </div>
                                        <div className="image">
                                            <img src={slide.image} alt={`Slide ${index + 1}`} />
                                        </div>
                                    </div>
                                
                                        <div className='omitir-container'><a href="/GanaConMastercardDebit/progreso">Omitir</a></div>
                                    

                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="swiper-button-prev swiper-button-prev-custom"></div>
                        {activeIndex === slides.length - 1 ? (
                            <div className='btn-start-progress'>
                                <Link to="/GanaConMastercardDebit/progreso" onClick={()=>{handleRedirectToProgreso();layerOnboarding()} }>Quiero empezar</Link>
                            </div>
                        ) : (
                            <div className="swiper-button-next swiper-button-next-custom" onClick={() => {                                                            
                                    layerOnboarding();                                
                            }}>
                                {context?.user?.segment === '2'
                                    ? activeIndex === 0
                                        ? 'Descubre cómo ganar'
                                        : 'Siguiente'
                                    : activeIndex === 3
                                        ? 'Quiero empezar'
                                        : 'Siguiente'
                                }
                            </div>
                        )}
                    </div >
                    :
                    <Preloader
                        type="fullscreen"
                        invert
                    />
            }
        </>
    );
};

export default Onboarding;