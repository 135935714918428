import React, { useContext, useState, useEffect } from 'react';

import Container from '../../layouts/Container/Container';
import ProgressBar from '../atoms/ProgressBar';
import Button from '../atoms/Button';
import TestQuestions from '../organisms/TestQuestions';
import useWidth from '../../hooks/useWidth';
import { StateController } from './../../App';
import { getPrice, getProgressPercent } from '../../utils/functions';
import GoalCounter from '../atoms/GoalCounter';
import { sendDataLayer } from '../../utils/functions';
import SliderRotator from '../organisms/SliderRotator';

function Progress() {
  const { width } = useWidth();
  const { context } = useContext(StateController);

  const [goalAmount, setGoalAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [goalCats, setGoalCats] = useState(0);
  const [activeTab, setActiveTab] = useState('meta');
  const [awards, setAwards] = useState([]);

  console.log("Context:", context);

  useEffect(() => {
    if (context && context.user) {
      setGoalAmount(context.user[`goal_amount_${context.block}`]);
      setGoalCats(context.user[`goal_category_${context.block}`]);
    }
    if (context && context.tracing) {
      setAmount(context.tracing[`amount_${context.block}`]);
    }

    if (context && context.awards && context.awards.block_1 ) {
      const list = []
      context.awards.block_1.awards.forEach((awrd) => {
        list.push({
          id: awrd.id,
          image: awrd.image
        })
      });
      setAwards(context.awards.block_1.awards)
    }
  }, [context]);

  const dataLayerProgress = (text, link) => {
    window.dataLayer.push({
      event: 'progress',
      text: text,
      link: link
    });
  }

  const dataLayerAllied = (e, content, link) => {
    sendDataLayer({
      datalayer: {
        event: 'alliances',
        text: content,
        link: link
      },
      repeat: true
    });
  }

  //datalayer premio mayor
  const dataLayerJackPot = () => {
    sendDataLayer({
      dataLayer: {
        event: "jackpot",
        text: "Conoce mas acerca del premio mayor",
        link: "/GanaConMastercardDebit/premio-mayor"
      },
      repeat: true
    });
  }

  const layer = () => {
    sendDataLayer({ datalayer: { event: 'progress', text: 'Ver Gift Cards', link: '/premios' }, repeat: false })
  }

  return (
    <div className='mc-page progress slow_ani'>
      <div className='mc-page__progress'>
        <Container>
          {width > 992 &&
            <div className='mc-page__progress--container__image'>
              <img src={`/assets/images/image_progress_desktop.png`} alt="" />

              <div className='mc-page__progress--container__awards'>
                <div className='mc-page__progress--container__awards--overlay'></div>
                <SliderRotator
                  mobileItemsToShow={4}
                  desktopItemsToShow={4}
                  simple={true}
                  list={
                    awards
                  }
                />


              </div>
            </div>
          }

          <div className='mc-page__progress--container'>
            <div className='mc-page__progress--title'>
              Conoce tu meta y sigue tu progreso
            </div>

            {context && context.user.segment === '1' ? (
              <div className='mc-page__progress--subtitle'>
                Realiza compras por un valor de {getPrice(goalAmount)} USD en {goalCats} categorías.
              </div>
            )
              :
              (
                <div className='mc-page__progress--subtitle'>
                  Realiza compras por un valor de {getPrice(goalAmount)} USD.
                </div>
              )
            }

            <div className='mc-page__progress--card'>
              <div className='mc-page__progress--tabs'>
                <button
                  className={`first ${activeTab === 'meta' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveTab('meta');
                    dataLayerProgress('Meta y Progreso', '/progreso');
                  }}
                >
                  Meta y Progreso
                </button>
                <button
                  className={`second ${activeTab === 'record' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveTab('record');
                    dataLayerProgress('Record de Meta', '/progreso');
                  }}
                >
                  Record de Meta
                </button>
              </div>

              {activeTab === 'meta' && (
                <div className='mc-section-g'>
                  <div className='mc-page__progress--card__t1'>
                    Así va tu meta en facturación:
                  </div>
                  <div className='mc-page__progress--card__t2'>
                    Llevas <strong>{getPrice(amount)} USD</strong> de <strong>{getPrice(goalAmount)} USD</strong>
                  </div>
                  <ProgressBar
                    percent={getProgressPercent(context)}
                    label={`10/25`}
                  />

                  {context && context.user.segment === '1' ? (
                    <p className='mc-page__progress--card__t3'>
                      Recuerda que para cumplir tu meta <strong>debes realizar compras en al menos {goalCats} de estas categorías:</strong>
                    </p>
                  )
                    :
                    (
                      <p className='mc-page__progress--card__t3'>
                        Recuerda que para cumplir tu meta <strong>debes realizar {goalCats} compras de al menos 10 USD cada una.</strong>
                      </p>
                    )
                  }
                  {context && context.user.segment === '1' && context.cats && (
                    <div className='mc-page__progress--card__cats'>


                      {context.cats.map((item, index) => {
                        let activeCat = false

                        if (context.tracing && context.tracing[`category_${item.id}`]) activeCat = true


                        return (
                        <span key={index} className={`${activeCat ? 'active' : ''}`}>
                          {item.name}
                          {activeCat &&
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                              <mask id="mask0_5588_12609" maskUnits="userSpaceOnUse" x="-1" y="0" width="17" height="17">
                                <rect x="-0.00012207" y="0.423096" width="16" height="16" fill="#D9D9D9" />
                              </mask>
                              <g mask="url(#mask0_5588_12609)">
                                <path d="M6.66654 11.4064L3.99988 8.73975L4.84988 7.88975L6.66654 9.70642L11.1499 5.22308L11.9999 6.07308L6.66654 11.4064Z" fill="#FDF2F8" />
                              </g>
                            </svg>
                          }
                        </span>
                        )
                      })}
                    </div>
                  )}
                  <div className='mc-page__progress--card__t4'>
                    Tu facturación tomará 10 días en verse reflejada. Última actualización: <strong>{context && context.tracing ? context.tracing.date_update : 'Sin actualizar'}</strong>
                  </div>
                  <div className='mc-page__progress--card__t5'>
                    ¡Logra tus metas y disfruta de una increíble Gift Card digital!
                  </div>
                  <div onClick={layer}>
                    <Button
                      type="primary"
                      text="Ver Gift Cards"
                      loading={false}
                      status="active"
                      link="/GanaConMastercardDebit/premios"
                    />
                  </div>
                </div>
              )}

              {activeTab === 'record' && (
                <div className='mc-section-g'>
                  <div className='mc-page__progress--card__t1'>
                    <div className='mc-page__progress--card__t1 pes_t2'>
                      Has cumplido tu meta:
                    </div>

                    <GoalCounter
                      label={``}
                    />
                    <ProgressBar
                      percent={getProgressPercent(context)}
                      label={`10/25`}
                    />
                    <div className='container-record-meta'>
                      {context && context.user.segment == '1' ? (
                        <p className='paragraph-container-goal'>
                          Cada vez que llegues a tu meta, <br />
                          tendrás <span>una oportunidad de ganar un <br /> viaje para dos a Río de Janeiro.</span>
                        </p>
                      ) : (
                        <p className='paragraph-container-goal'>
                          Cada vez que llegues a tu meta, <br />
                          tendrás <span>una oportunidad de ganar un <br />kit de Apple.</span>
                        </p>
                      )}
                      < a onClick={dataLayerJackPot} href="/GanaConMastercardDebit/premio-mayor">Conoce más acerca del premio mayor</a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </div >

      <div className='mc-page__progress--extra'>
        <Container>
          <img src={`/assets/images/banner_progress${width > 992 ? '_desktop' : ''}${(context && context.user.segment == '0') ? '_inactivo' : ''}.png`} alt="" />
          <div className='mc-page__progress--extra__t1'>
            Aprovecha estas alianzas que <strong>te ayudarán a cumplir tu meta</strong>
          </div>
          <div className='mc-page__progress--extra__t2'>
            <div className='mc-page__progress--extra__t2--item' onClick={(e) => dataLayerAllied(e, "Carl's JR", '/premios')}>
              <div className='mc-page__progress--extra__t2--item__image border_ajuste'>
                <img src="/assets/images/combo_classic_border.png" alt="" />
              </div>
              <div className='mc-page__progress--extra__t2--item__labels'>
                <h3>Carl{`'`}s JR</h3>
                <p>Paga con tus tarjetas Mastercard y disfruta del <strong>Classic Combo por solo $4,90</strong>. <span><br /> <br />(Precio normal $7,30)</span></p>
              </div>
            </div>
            <div className='mc-page__progress--extra__t2--item' onClick={(e) => dataLayerAllied(e, "Carl's JR", '/premios')}>
              <div className='mc-page__progress--extra__t2--item__image border_ajuste'>
                <img src="/assets/images/super_duo_border.png" alt="" />
              </div>
              <div className='mc-page__progress--extra__t2--item__labels'>
                <h3>Carl{`'`}s JR</h3>
                <p><strong>Disfruta del Super Duo a $9,90</strong> con tus tarjetas Mastercard. <span> <br /> <br />(Precio normal $11,90)</span></p>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {
        context &&
        <div onClick={() => dataLayerProgress('Test Questions', '/progress')}>
          <TestQuestions context={context}
          />
        </div>
      }
    </div >
  );
}

export default Progress;
