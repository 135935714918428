import React from 'react'
import { useNavigate } from 'react-router-dom';

const GoBack = () => {
    const navigate = useNavigate();
    const handleGoBack = (event) => {
        event.preventDefault();
        navigate('/GanaConMastercardDebit');    
      };

    return (
        <>
            <div className='GoBack'>
                <button onClick={handleGoBack} className='btn btn-goback' >
                    <img src="/assets/images/undo.png" alt="icono de volver atrás" />
                    Volver a la página anterior
                </button>                
            </div>
        </>
    )
}

export default GoBack
