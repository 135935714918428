import React, { useContext, useEffect } from 'react'

import Container from '../../layouts/Container/Container'
import PrimaryBanner from '../molecules/PrimaryBanner'
import FaqsFeed from '../organisms/FaqsFeed'
import { StateController } from '../../App';

function Faqs() {
  const { staticContent } = useContext(StateController);

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])
  
  return (
    <div className='mc-page faqs slow_ani'>

      <PrimaryBanner
        goBack
        title={<>Preguntas frecuentes</>}
        size={'small'}
        description={<>Encuentra la respuesta a todas tus preguntas y sigue ganando con tu Mastercard Debit Banco Guayaquil.</>}    
      />

      <Container className={'spacing'}>        
        <FaqsFeed
          faqsType={1}
          list={staticContent?.faqs}
        />
      </Container>
      
    </div>
  )
}
export default Faqs