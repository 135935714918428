import React, { useState } from 'react'

import Accordeon from '../atoms/Accordeon'
import Preloader from '../atoms/Preloader'

function FaqsFeed({
  faqsType,
  list
}) {
  const [allItems, setAllItems] = useState(false)

  return (
    <div className={`mc-faqs-container mc-faqs-container-type-${faqsType}`}>
      {
        list ?
      list.map((faq, index) => {
        return (
          <Accordeon
            key={index}
            title={faq.title}
            content={faq.content}
            setAllItems={setAllItems}
            allItems={allItems}
          />
        )
      })
      :
      <Preloader type='section' invert />
      }
    </div>
  )
}

export default FaqsFeed