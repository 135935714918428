import React, { useEffect, useState, useContext } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { StateController } from './../../App';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

function Mechanic() {
    const { context } = useContext(StateController);
    const segment = context?.user?.segment;


    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 998);
        };
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (window.location.pathname.includes("landing")) {
            document.body.classList.add("landing-page");
        } else {
            document.body.classList.remove("landing-page");
        }
    }, []);

    return (
        <div className='mc-page mechanic slow_ani mc-container-landing'>
            {
                segment === '1' ? (
                    <div className='__page'>
                        <div className='__text'>
                            <div className="__container">
                                <div>
                                    <h1>Mecánica</h1>
                                </div>
                                <p>Sigue estos pasos y gana con tu Mastercard Debit Banco Guayaquil:</p>
                            </div>
                        </div>
                        <div className='__cards-container'>
                            {isMobile ? (<div className='__cards-container-vertical'>
                                <div className='__card'>
                                    <div className='__img'>
                                        <img src='/assets/images/img_card_1.png' alt="" />
                                    </div>
                                    <div className='__text-card'>
                                        <p><strong>1.</strong> Conoce tu meta en <br />facturación y las categorías <br />que te llevarán a alcanzarla.</p>
                                    </div>
                                </div>
                                <div className='__card'>
                                    <div className='__img'>
                                        <img src='/assets/images/im_card_2_1.png' alt="" />
                                    </div>
                                    <div className='__text-card'>
                                        <p><strong>2.</strong> Usa tu Mastercard Debit Banco Guayaquil en <br />todas tus compras para <br />alcanzar la meta.</p>
                                    </div>
                                </div>
                                <div className='__card'>
                                    <div className='__img'>
                                        <img src='/assets/images/img_card_premios.png' alt="" />
                                    </div>
                                    <div className='__text-card'>
                                        <p><strong>3.</strong> Redime la Gift Card digital <br />que más te guste y apúntale a <br />ganar el premio mayor.</p>
                                    </div>
                                </div>
                                <div className='__card'>
                                    <div className='__img'>
                                        <img src='/assets/images/im_card_4.png' alt="" />
                                    </div>
                                    <div className='__text-card'>
                                        {/* <p><strong>4.</strong>  Mientras más veces alcances tu meta mas probabilidades tendrás de participar por un increíble viaje a Río de Janeiro o un Kit de Apple.<br /></p> */}
                                        <p><strong>4.</strong> ¡Gana un viaje a Río de Janeiro para dos!<br /></p>
                                        <h6>Podrás participar, cada vez que cumplas tu <br /> meta. <a style={{ color: '#D2006E' }} href="/terminos-y-condiciones">Aplica términos y condiciones</a></h6>
                                    </div>
                                </div>
                            </div>
                            ) : (<Swiper
                                ccssMode={true}
                                navigation={true}
                                pagination={{ clickable: true }}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                                className='__swiper'
                                spaceBetween={1}
                                slidesPerView={3.6}
                                loop={false}
                            >
                                <SwiperSlide>
                                    <div className='__card'>
                                        <div className='__img'>
                                            <img src='/assets/images/img_card_1.png' alt="" />
                                        </div>
                                        <div className='__text-card'>
                                            <p><strong>1.</strong> Conoce tu meta en <br />facturación y las categorías <br />que te llevarán a alcanzarla.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='__card'>
                                        <div className='__img'>
                                            <img src='/assets/images/im_card_2_1.png' alt="" />
                                        </div>
                                        <div className='__text-card'>
                                            <p><strong>2.</strong> Usa tu Mastercard Debit Banco Guayaquil en <br />todas tus compras para <br />alcanzar la meta.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='__card'>
                                        <div className='__img'>
                                            <img src='/assets/images/img_card_premios.png' alt="" />
                                        </div>
                                        <div className='__text-card'>
                                            <p><strong>3.</strong> Redime la Gift Card digital <br />que más te guste y apúntale a <br />ganar el premio mayor.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='__card'>
                                        <div className='__img'>
                                            <img src='/assets/images/im_card_4.png' alt="" />
                                        </div>
                                        <div className='__text-card'>
                                            {/* <p><strong>4.</strong> Mientras más veces alcances tu meta mas probabilidades tendrás de participar por un increíble viaje a Río de Janeiro o un Kit de Apple.<br /></p> */}
                                            <p><strong>4.</strong> ¡Gana un viaje a Río de Janeiro para dos!<br /></p>
                                            <h6>Podrás participar, cada vez que cumplas tu <br /> meta. <a style={{ color: '#D2006E' }} href="/GanaConMastercardDebit/terminos-y-condiciones">Aplica términos y condiciones</a></h6>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <div className="swiper-pagination"></div>
                            </Swiper>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className='__page'>
                        <div className='__text'>
                            <div className="__container">
                                <div>
                                    <h1>Mecánica</h1>
                                </div>
                                <p>Sigue estos pasos y gana con tu Mastercard Debit Banco Guayaquil:</p>
                            </div>
                        </div>
                        <div className='__cards-container'>
                            {isMobile ? (<div className='__cards-container-vertical'>
                                <div className='__card'>
                                    <div className='__img'>
                                        <img src='/assets/images/inactive_img_card_1.png' alt="" />
                                    </div>
                                    <div className='__text-card'>
                                        <p><strong>1.</strong> Conoce tu meta de <br />facturación y cantidad de<br />transacciones.</p>
                                    </div>
                                </div>
                                <div className='__card'>
                                    <div className='__img'>
                                        <img src='/assets/images/inactive_img_card_2.png' alt="" />
                                    </div>
                                    <div className='__text-card'>
                                        <p><strong>2.</strong> Realiza todas tus compras <br /> con tu Tarjeta Matercard Debit <br /> y cumple tu metas.</p>
                                    </div>
                                </div>
                                <div className='__card'>
                                    <div className='__img'>
                                        <img src='/assets/images/inactive_img_card_3.png' alt="" />
                                    </div>
                                    <div className='__text-card'>
                                        <p><strong>3.</strong> ¡Elige tu Gift Card favorita y <br /> redímela en las tiendas que <br /> más te gustan!</p>
                                    </div>
                                </div>
                                <div className='__card'>
                                    <div className='__img'>
                                        <img src='/assets/images/inactive_img_card_4.png' alt="" />
                                    </div>
                                    <div className='__text-card'>
                                        <p><strong>4.</strong> ¡Un kit de Apple podría <br /> ser tuyo!<br /></p>
                                        {/* <p><strong>4.</strong> ¡El premio mayor puede ser tuyo!<br /></p> */}
                                        <h6>Mientras más veces cumplas tu meta, más <br /> oportunidades tienes de ganar este increíble <br /> kit.</h6>
                                    </div>
                                </div>
                            </div>
                            ) : (<Swiper
                                ccssMode={true}
                                navigation={true}
                                pagination={{ clickable: true }}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                                className='__swiper'
                                spaceBetween={1}
                                slidesPerView={3.6}
                                loop={false}
                            >
                                <SwiperSlide>
                                    <div className='__card'>
                                        <div className='__img'>
                                            <img src='/assets/images/inactive_img_card_1.png' alt="" />
                                        </div>
                                        <div className='__text-card'>
                                            <p><strong>1.</strong> Conoce tu meta de <br />facturación y cantidad de <br /> transacciones.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='__card'>
                                        <div className='__img'>
                                            <img src='/assets/images/inactive_img_card_2.png' alt="" />
                                        </div>
                                        <div className='__text-card'>
                                            <p><strong>2.</strong> Realiza todas tus compras <br /> con tu Tarjeta Matercard <br /> Debit y cumple tu metas.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='__card'>
                                        <div className='__img'>
                                            <img src='/assets/images/inactive_img_card_3.png' alt="" />
                                        </div>
                                        <div className='__text-card'>
                                            <p><strong>3.</strong> ¡Elige tu Gift Card favorita <br /> y redímela en las tiendas que <br />más te gustan!</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='__card'>
                                        <div className='__img'>
                                            <img src='/assets/images/inactive_img_card_4.png' alt="" />
                                        </div>
                                        <div className='__text-card'>
                                            <p><strong>4.</strong> ¡Un kit de Apple podría <br /> ser tuyo!<br /></p>
                                            {/* <p><strong>4.</strong> ¡El premio mayor puede ser tuyo!<br /></p> */}
                                            <h6>Mientras más veces cumplas <br /> tu meta, más oportunidades <br /> tienes de ganar este <br /> increíble kit.</h6>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <div className="swiper-pagination"></div>
                            </Swiper>
                            )}
                        </div>
                    </div>
                )
            }
        </div>
    )
}
export default Mechanic