import React, { useContext, useEffect, useState } from 'react'
import { StateController } from './../../App'
import TestQuestions from '../organisms/TestQuestions'

function MajorAward() {
  const { context } = useContext(StateController)
  const segment = context?.user?.segment ? context.user.segment : '0';

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 998);
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <div className='mc-page majorAward slow_ani'>

      {segment === '1' ? (<div className='container-majorAward'>
        {
          !isMobile ? (
            <div className='banner-one'>
              <div className="banner-major-award">
                <div className='text-major-award-container'>
                  <div className='title-container'>
                    <h1 className='title'>
                      ¡Llévate el premio mayor! Con tu tarjeta Mastercard Debit Banco Guayaquil, <span>Río de Janeiro</span> está más cerca
                    </h1>
                  </div>
                  <div className='info-container'>
                    <div className='sub-title-container'>
                      <h2 className='subtitle'>¿Como se seleccionará <br />
                        <span> a los ganadores de estos premios?</span></h2>
                    </div>
                    <div className='paragraph-container'>
                      <p className='paragraph'>Se seleccionará al azar a un (1) ganador que recibirá como premio <span>dos tiquetes de avión para viajar a
                        Río de Janeiro, Brasil.</span></p>
                    </div>
                  </div>
                </div>
                <div className='img-container'>
                  <img className='img-banner' src="/assets/images/banner_premio_mayor.png" alt="" />
                </div>
              </div>
            </div>
          ) : (
            <div className='banner-one'>
              <div className="banner-major-award">
                <div className='text-major-award-color'>
                  <div className='title-container'>
                    <h1 className='title'>
                      ¡Llévate el premio mayor! Con tu tarjeta Mastercard Debit Banco Guayaquil, <span>Río de Janeiro</span> está más cerca
                    </h1>
                  </div>
                </div>
                <div className='text-major-award-nocolor'>
                  <div className='info-container'>
                    <div className='sub-title-container'>
                      <h2 className='subtitle'>¿Como se seleccionará <br />
                        <span> a los ganadores de estos premios?</span></h2>
                    </div>
                    <div className='paragraph-container'>
                      <p className='paragraph'>Se seleccionará al azar a un (1) ganador que recibirá como premio <span>dos tiquetes de avión para viajar a <br />
                        Río de Janeiro, Brasil.</span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        <div className='container-banner-two'>
          <div className='container-img-banner-two'>
            <img src="/assets/images/premio_mayor_img_1.png" alt="" />
          </div>
          <div className='container-text-banner-two'>
            <h2>¿Qué incluye?</h2>
            <h3><span className='point-pink'>. </span> Pasajes de ida y vuelta:</h3>
            {/* <h3><li>Pasajes de ida y vuelta:</li></h3>
            <ul>
              <li>Vuelta: 30 de noviembre de 2024 - Río de Janeiro - Escala en Panamá (Xh XXm) - Quito.</li>
            </ul> */}
            <p className='subpar'><span className='point-black'>. </span>Ida: 25 de noviembre de 2024 - Quito - Escala en Bogotá - Río de Janeiro.</p>
            <p className='subpar'><span className='point-black'>. </span>Vuelta: 30 de noviembre de 2024 - Río de Janeiro - Escala en Panamá - Quito.</p>
            <p><span className='point-pink'>. </span><span className='title-strong'>Alquiler de automóvil: </span>Del 26 al 30 de noviembre de 2024.</p>
            <p><span className='point-pink'>. </span><span className='title-strong'>Alojamiento: </span> 4 días y 3 noches en el Hotel Intercity Rio De Janeiro  Porto Maravilha, con desayuno incluido.</p>
            <p><span className='point-pink'>. </span><span className='title-strong'>Tour por Río de Janeiro:</span>Visitas al Cristo Redentor, Pan de Azúcar, y  el estadio Maracaná.</p>
            <p> También incluye el Sambódromo y la Catedral Metropolitana. Buffet  incluido.</p>
            <p><span className='point-pink'>. </span><span className='title-strong'>Tour por el Jardín Botánico, Bosque de Tijuca y Parque Lage: </span> Explora el Jardín Botánico, el Bosque de Tijuca y el Parque Lage en  un día lleno de naturaleza.</p>
            <p><span className='point-pink'>. </span><span className='title-strong'>Seguro de viaje.</span></p>
            <p><span className='point-pink'>. </span><span className='title-strong'>Impuestos hoteleros</span></p>
          </div>
        </div>

        <div className="container-banner-three">
          <div className='container-text-banner-three'>
            <h2>¿Cómo podrás participar <br />
              <span>por este premio?</span></h2>
            <p>Cada vez que alcances tu meta, tendrás una oportunidad para ganar <span>un viaje a Río de Janeiro para dos personas.</span></p>
            <p>Puedes acumular hasta <span>5 oportunidades</span> para participar en este increíble premio.</p>
            <p>*Aplican Términos y Condiciones, <a href="/GanaConMastercardDebit/terminos-y-condiciones">consulta los detalles aquí.</a></p>
          </div>
          <div className='container-img-banner-three'>
            <img src="/assets/images/premio_mayor_img_2.png" alt="" />
          </div>
        </div>
      </div>) : (
        <div className='container-majorAward'>
          {
            !isMobile ? (
              <div className='banner-one'>
                <div className="banner-major-award">
                  <div className='text-major-award-container'>
                    <div className='title-container'>
                      <h1 className='title'>
                        <span>¡Participa por un kit de Apple!</span> <br />
                        Gana un Macbook Air M2 2022, iPhone 15 Pro 256 GB y Apple Watch Serie 9 45mm
                      </h1>
                    </div>
                    <div className='info-container'>
                      <div className='sub-title-container'>
                        <h2 className='subtitle'>¿Como se seleccionará <br />
                          <span> a los ganadores de estos premios?</span></h2>
                      </div>
                      <div className='paragraph-container'>
                        <p className='paragraph'>Se seleccionará al azar a un (1) ganador <span>que recibirá un kit de Apple.</span></p>
                      </div>
                    </div>
                  </div>
                  <div className='img-container inactivo'>
                    <img className='img-banner' src="/assets/images/inactivo_premio_mayor.png" alt="" />
                  </div>
                </div>
              </div>
            ) : (
              <div className='banner-one inactive-mobile'>
                <div className="banner-major-award">
                  <div className='text-major-award-color'>
                    <div className='title-container'>
                      <h1 className='title'>
                        <span>¡Participa por un <br /> kit de Apple! </span>Gana un Macbook Air M2 2022, iPhone 15 Pro 256 GB y Apple Watch Serie 9 45mm.
                      </h1>
                    </div>
                  </div>
                  <div className='img-container inactivo'>
                    <img className='inactive-img img-banner' src="/assets/images/inactivo_premio_mayor.png" alt="" />
                  </div>
                  <div className='text-major-award-nocolor'>
                    <div className='info-container'>
                      <div className='sub-title-container'>
                        <h2 className='subtitle'>¿Como se seleccionará <br />
                          <span> a los ganadores de estos premios?</span></h2>
                      </div>
                      <div className='paragraph-container'>
                        <p className='paragraph'>Se seleccionará al azar a un (1) ganador <span>que recibirá un kit de Apple.</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          {
            !isMobile ? (<div className='container-banner-two inactivos'>
              <div className='container-img-banner-two'>
                <img src="/assets/images/inactivo_premio_mayor_2.png" alt="" />
              </div>
              <div className='container-text-banner-two'>
                <h2>¿Qué incluye?</h2>
                <h3><span className='point-pink'>. </span> Macbook Air M2 2022</h3>
                <h3><span className='point-pink'>. </span> IPhone 15 Pro 256 GB</h3>
                <h3><span className='point-pink'>. </span> Apple Watch Serie 9 45mm</h3>

                <h2> <span>¿Cómo podrás participar</span><br />
                  por este premio?</h2>
                <p>Cada vez que alcances tu meta, tendrás una oportunidad <br /> para <strong>participar por el Kit de Apple.</strong></p>
                <p>Puedes acumular hasta <strong>5 oportunidades</strong> para participar en este increíble premio.</p>
                <p>*Aplican Términos y Condiciones,<a href="/GanaConMastercardDebit/terminos-y-condiciones"> consulta los detalles aquí.</a></p>
              </div>
            </div>) : (<div className='container-banner-two inactivos'>

              <div className='container-text-banner-two'>
                <div>
                  <h2>¿Qué incluye?</h2>
                  <h3><span className='point-pink'>. </span> Macbook Air M2 2022</h3>
                  <h3><span className='point-pink'>. </span> IPhone 15 Pro 256 GB</h3>
                  <h3><span className='point-pink'>. </span> Apple Watch Serie 9 45mm</h3>
                </div>
                <div className='container-img-banner-two'>
                  <img src="/assets/images/inactivo_premio_mayor_2.png" alt="" />
                </div>
                <div>
                  <h2> <span>¿Cómo podrás participar</span><br />
                    por este premio?</h2>
                  <p>Cada vez que alcances tu meta, tendrás una oportunidad <br /> para <strong>participar por el Kit de Apple.</strong></p>
                  <p>Puedes acumular hasta <strong>5 oportunidades</strong> para participar en este increíble premio.</p>
                  <p>*Aplican Términos y Condiciones,<a href="/GanaConMastercardDebit/terminos-y-condiciones"> consulta los detalles aquí.</a></p>
                </div>
              </div>
            </div>)
          }

        </div>
      )}


      {
        context &&
        <TestQuestions context={context} />
      }
    </div >
  )
}
export default MajorAward