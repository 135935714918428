import React from 'react'

// import Container from '../../layouts/Container/Container'
import LoginForm from '../organisms/LoginForm'
// import Banner from '../organisms/Banner'
// import BlackTitle from '../atoms/BlackTitle'
import HeaderLanding from '../../layouts/Header/HeaderLanding'

function Login({
  autologin,
  hash
}) {

  return (
    <>
      <HeaderLanding />
      <div className='mc-page login slow_ani'>
        {/* <Container> */}
        <LoginForm
          title="Título corto"
          type="credentials"
          fields={[
            { field: "code", label: "Introduce el código recibido por correo", placeholder: "Escribe tu código", maxlength: 8, separated: false },
            { field: "terms", label: "He leído y acepto los ", termsText: "Términos y condiciones", termsLink: "/GanaConMastercardDebit/terminos-y-condiciones" } // DO NOT CHANGE THIS LINE
          ]}
          buttonText="Ingresar"
          background="#fff"
          autologin={autologin}
          hash={hash}
        />
        {/* </Container> */}

      </div>
    </>

  )
}
export default Login