import React from 'react'

const ConfimDownload = ({showConfirm}) => {
  return (
    <div className={`ConfimDownload ${showConfirm ? 'show' : ''}`}>
      <div className="icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
  <circle cx="17.5" cy="17.5" r="17.5" fill="#D2006E"/>
  <mask id="mask0_519_4939" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="6" y="6" width="24" height="24">
    <rect x="6" y="6" width="24" height="24" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_519_4939)">
    <path d="M15.5502 21.15L24.0252 12.675C24.2252 12.475 24.4627 12.375 24.7377 12.375C25.0127 12.375 25.2502 12.475 25.4502 12.675C25.6502 12.875 25.7502 13.1125 25.7502 13.3875C25.7502 13.6625 25.6502 13.9 25.4502 14.1L16.2502 23.3C16.0502 23.5 15.8169 23.6 15.5502 23.6C15.2835 23.6 15.0502 23.5 14.8502 23.3L10.5502 19C10.3502 18.8 10.2544 18.5625 10.2627 18.2875C10.271 18.0125 10.3752 17.775 10.5752 17.575C10.7752 17.375 11.0127 17.275 11.2877 17.275C11.5627 17.275 11.8002 17.375 12.0002 17.575L15.5502 21.15Z" fill="#FDF2F8"/>
  </g>
</svg>
      </div>
      <p>
      Gift Card descargada con éxito
      </p>
    </div>
  )
}

export default ConfimDownload
