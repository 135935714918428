import React from 'react'
import { useNavigate } from 'react-router-dom'

function Button({
  type,
  text,
  loading,
  status,
  onClick,
  link,
  icon,
  showErrorRedemtion
}) {
  const navigate = useNavigate()
  const handler = () => {

    if (status === 'active' && !loading) {
      link
        ? navigate(link)
        : onClick()
    }
  }

  return (
    <div
      className={`mc-button-container mc-button-container__${type}`}
      onClick={handler}
    >
      <button className={`slow_ani mc-button-container__status--${status}`}>
        {loading
          ? <img src='/assets/images/preloader_1.gif' alt='loader' className='inline-loader' />
          : text
        }
        {
          (icon && !showErrorRedemtion) && <img className='button_icon' src={`/assets/images/${icon}`} alt="" />
        }
      </button>
    </div>
  )
}

export default Button