import React from 'react'
import Container from '../../layouts/Container/Container'
import GoBack from './GoBack'

function PrimaryBanner({
  goBack,
  title,
  description,
  size
}) {

  return (
    <div className='mc-banner-container'>
      <Container fullHeight className={'customBanner'}>
        <div className={`rowCols ${!goBack ? 'spacing': ''}`}>
          <div className="banner-content">
            {
              goBack && <GoBack />
            }
            
            {
              title &&
              <h1 className={`banner-title ${size === 'small' ? 'title_small':''}`}>{title}</h1>
            }
            {
              description &&
              <p className='banner-description'>
                {description}              
              </p>
            }
          </div>
        </div>
      </Container>
    </div>
  )
}

export default PrimaryBanner