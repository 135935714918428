import React, { useContext, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { StateController } from './../../App';
import AwardBox from '../molecules/AwardBox';
import { itsFiltered, sendDataLayer } from '../../utils/functions';

function AwardsGallery({
  awardBoxType,
  itemsPerLine,
  limit,
  limitButtonText,
  list,
  canRedem,
  termText,
  termLink,
  termModal,
  redemtionText,
  steps,
  showStock,
  download,
  twoStepAuth,
  setShowConfirm
}) {
  const { context } = useContext(StateController);
  const [opts, setOpts] = useState([]);
  const [currentGallery, setCurrentGallery] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(false);
  
  const itemsPerPage = isMobile ? 6 : 9;

  useEffect(() => {
    if (context && context.awards) {
      setOpts(
        Object.keys(context.awards).map((key) => context.awards[key])
      );
    }
  }, [context]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 998);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const stepper_settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    customPaging: function (i) {
      return (
        <span key={i}>
          <p>{`Mes ${i + 1}`}</p>
        </span>
      );
    }
  };

  const galleryToggler = (e) => {
    setCurrentGallery(e.target.value);
  };

  const layer = () => {
    sendDataLayer({ datalayer: { event: 'progress', text: 'Ver todos los comercios disponibles', link: '/premios' }, repeat: false });
  };

  const paginatedList = list.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(list.length / itemsPerPage);

  return (
    <div className={`mc-awards-container mc-awards-column-${itemsPerLine}`}>

      {context && context.allAwards && context.allAwards[0] &&
        <div className="mc-awards-container__selector">
          <select name="gallery-selector" id="gallery-selector" value={currentGallery} onChange={galleryToggler}>
            <option value="">Filtrar por valor</option>
            {itsFiltered(20000, context.availableForRedemtion) && <option value="20000">$20.000</option>}
          </select>
        </div>
      }

      {steps
        ? <div className="mc-awards-container__steper">
          <Slider {...stepper_settings}>
            {opts.map((step, index) => (
              <div className="mc-awards-container__items" key={index}>
                {step.awards.map((item, index) => (
                  <div key={index} className={`mc-awards-container__items--item type-${awardBoxType}`}>
                    <AwardBox
                      type={awardBoxType}
                      award={item}
                      canRedem={canRedem}
                      termLink={termLink}
                      termText={termText}
                      termModal={termModal}
                      redemtionText={redemtionText}
                      showStock={showStock}
                      download={download}
                      twoStepAuth={twoStepAuth}
                      setShowConfirm={setShowConfirm}
                      position={index+1} // Añade esta línea para pasar la posición
                    />
                  </div>
                ))}
              </div>
            ))}
          </Slider>
        </div>
        : list && list[0]
          ? <div className="mc-awards-container__items">
            {paginatedList.map((item, index) => {
              if (download && item.prepurchased === 1) item.prepurchased = true;
              if (download && item.prepurchased === 0) item.prepurchased = false;

              return (
                <div key={index} className={`mc-awards-container__items--item type-${awardBoxType}`}>
                  <AwardBox
                    type={awardBoxType}
                    award={item}
                    canRedem={canRedem}
                    termLink={termLink}
                    termText={termText}
                    termModal={termModal}
                    redemtionText={redemtionText}
                    showStock={false}
                    download={download}
                    twoStepAuth={twoStepAuth}
                    setShowConfirm={setShowConfirm}
                    // position={index + (currentPage - 1) * itemsPerPage} // Calcula y pasa la posición correcta
                    position={index+1} // Calcula y pasa la posición correcta
                  />
                </div>
              );
            })}
          </div>
          : <div className='empty-awards'>
              Aquí encontrarás tu Gift Card una vez la canjees
            </div>
      }

      {totalPages > 1 &&
        <div className="pagination">
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="arrow">
            &lt;
          </button>
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={currentPage === i + 1 ? 'active' : ''}
            >
              {i + 1}
            </button>
          ))}
          <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="arrow">
            &gt;
          </button>
        </div>
      }

      {limit && limitButtonText && (list && list[0]) &&
        <div className="mc-awards-container__actions" onClick={layer}>
          <Link to="/premios">{limitButtonText}</Link>
        </div>
      }
    </div>
  );
}

export default AwardsGallery;
