import React, { useEffect, useState, createContext } from 'react'
import {
  Route,
  Routes,
  useLocation,
  useSearchParams,
  useNavigate
} from 'react-router-dom'
import { connect } from 'react-redux'

// pages
import Login from "./components/pages/Login"
import Mechanic from "./components/pages/Mechanic"
import Progress from "./components/pages/Progress"
import Awards from "./components/pages/Awards"
import Redemptions from "./components/pages/Redemptions"
import Terms from "./components/pages/Terms"
import Faqs from "./components/pages/Faqs"
import MajorAward from "./components/pages/MajorAward"
import Dashboard from "./components/pages/Dashboard"
import NotFound from "./components/pages/NotFound"
import Landing from './components/pages/Landing'
import Onboarding from './components/pages/Onboarding'
// layout
import Header from "./layouts/Header/Header"
import Footer from "./layouts/Footer/Footer"

// components
import CustomModal from './components/molecules/CustomModal'
import { sendDataLayer } from './utils/functions'
// actions
import {
  logOutAction,
  loginShaAction
} from './store/sessionDuck'
import useWidth from './hooks/useWidth'

import {
  gtUser,
  gtStaticContent
} from './utils/helpers'
import { getPrice } from './utils/functions'
import { doRedeem } from './utils/helpers'

import './styles/main.scss'
import CustomModalLogin from './components/molecules/CustomModalLogin'

export const StateController = createContext(null)

function App({
  error,
  token,
  loggedIn,
  logOutAction
}) {
  const { width } = useWidth()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [modalOpen, setMdalOpen] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [context, setContext] = useState(false)
  const [staticContent, setStaticContent] = useState(false)
  const [autologinPreloader, setAutologinPreloader] = useState(false)

  const [awardSelected, setAwardSelected] = useState({})
  const [showConfirm, setShowConfirm] = useState(false)
  const [redemtionLoading, setRedemtionLoading] = useState(false)
  const [showErrorRedemtion, setShowErrorRedemtion] = useState(false)
  const [showSuccessRedemtion, setShowSuccessRedemtion] = useState(false)

  const home_page = '/GanaConMastercardDebit/onboarding'
  const pages = [
    { page: 'GanaConMastercardDebit/progreso', title: 'Progreso', icon: 'progress', sublevel: false, component: <Progress privatePage /> },
    { page: 'GanaConMastercardDebit/mecanica', title: 'Mecánica', icon: 'mechanic', sublevel: false, component: <Mechanic privatePage /> },
    { page: 'GanaConMastercardDebit/premios', title: 'Catálogo', icon: 'catalog', sublevel: false, component: <Awards privatePage /> },

    { page: 'GanaConMastercardDebit/mis-redenciones', title: 'Canjes', icon: 'canjes', sublevel: false, onlyDesktop: true, component: <Redemptions privatePage /> },
    { page: 'GanaConMastercardDebit/premio-mayor', title: 'Premio mayor', icon: 'major-award', sublevel: false, onlyDesktop: true, component: <MajorAward privatePage /> },

    {
      page: 'mas', title: 'Más', icon: 'moremenu', sublevel: true, children: [
        { page: 'GanaConMastercardDebit/mis-redenciones', title: 'Canjes', component: <Redemptions privatePage /> },
        { page: 'GanaConMastercardDebit/premio-mayor', title: 'Premio mayor', component: <MajorAward privatePage /> }
      ]
    }
  ]

  const pathURL = {
    "tZNsUTFTPw": "progreso",
    "WtwuqKUmjp": "mecanica",
    "JnXnducsYR": "premios",
    "MsFaj6v12C": "mis-redenciones",
    "pMLl7K7NL40": "premio-mayor",
    "dW01D9ysQJ7": "onboarding"
  }

console.log('location',location)
  const closeLoginModal = () => {
    setAutologinPreloader(false)
    setNotFound(false)
    localStorage.removeItem('userNotFound')
    window.location.href = '/GanaConMastercardDebit'
  }

  useEffect(() => {
    if (localStorage.getItem('userNotFound')) {
      setNotFound(true)

      setTimeout(() => {
        localStorage.removeItem('userNotFound')
      }, 3000)
    } else {
      setNotFound(false)
    }
  }, [location])

  useEffect(() => {
    if (searchParams.get('utm_web')) {
      setAutologinPreloader(true)
    }

    if (!localStorage.getItem('storage')) {
      if (window.location.search !== '') {
        console.log("Navegate to:", window.location.search)
        // navigate(window.location.search)
      } else {
        const public_url = pages.find((page) => page.page === location.pathname.replace('/', ''))
        const public_children_urls = pages.find((page) => page.sublevel)

        if (public_url) {
          if (!loggedIn && public_url?.component?.props?.privatePage) document.location.href = '/GanaConMastercardDebit'

        } else if (public_children_urls) {

          const public_child_url = public_children_urls?.children.find((childPage) => childPage.page === location.pathname.replace('/', ''))
          if (public_child_url?.component?.props?.privatePage) navigate('/GanaConMastercardDebit')
        }
      }
    }
  }, [searchParams, error])


  useEffect(() => {

    if (location.pathname === '/') {
      window.location.href = `/GanaConMastercardDebit/${location.search}`
    }
    if (location.pathname === '/preguntas-frecuentes') {
      window.location.href = `/GanaConMastercardDebit/preguntas-frecuentes`
    }
    if (location.pathname === '/terminos-y-condiciones') {
      window.location.href = `/GanaConMastercardDebit/terminos-y-condiciones`
    }


    if (token !== '') {
      gtUser(token).then((user) => {

        if (user) {

          if (user.data) {
            setContext(user.data)
            console.log("User data:", user.data, window.location.pathname, home_page)

            if (searchParams.get('pathurl')) {
              const path = pathURL[searchParams.get('pathurl')] || home_page
              let queryparams = ''
  
              for(const x of searchParams.keys()){
                if(!(x === "utm_web" || x==='pathurl')){
                  queryparams += `${x}=${searchParams.get(x)}&`
                }
              }
              queryparams = queryparams.slice(0, queryparams.length - 1)
              window.location.href = `${path}${(queryparams && queryparams !== '') && '?'}${queryparams}`
            } else {
              console.log(window.location.pathname, home_page )
              if (window.location.pathname.toLowerCase() !== home_page.toLowerCase() && (window.location.pathname.toLowerCase() === '/ganaconmastercarddebit' || window.location.pathname.toLowerCase() === '/ganaconmastercarddebit/')) {
                window.location.href = home_page
              }
            }

            /*
            
              */

            if (user?.data?.tracing && user?.data?.tracing[`winner_${user.data.block}`] === 1) {
              // sendDataLayer({datalayer: { event: 'goal' },repeat: false})
              window.localStorage.setItem('goal','true')
            }

          } else if (user.detail && user.detail.includes('Database conection error.')) {
            console.log("Error en base de datos...", user)
            localStorage.removeItem('storage')
            window.location.reload()
          } else if (user.message && user.message.includes('nauthorized')) {
            console.log("No autorizado")
            localStorage.removeItem('storage')
            window.location.reload()
          }

        } else if (user.statusCode === 301 && localStorage.getItem('storage') && window.location.pathname === '/') {
          localStorage.removeItem('storage')
          window.location.reload()
        }
        if (user?.statusCode === 301 && window.location.pathname !== '/' && window.location.pathname !== '/dashboard') {
          window.location.href = '/GanaConMastercardDebit'
        }
      }).catch(() => {
        // navigate('/')
      })

    }
  }, [token])

  useEffect(() => {

    gtStaticContent().then((content) => {
      if (content && content.content) setStaticContent(content.content)
    })
  }, [])

  // const handlerModal = () => {
  //   setShowConfirm(!showConfirm)

  // }
  const handlerModal = () => {
    if (showConfirm) {
      sendDataLayer({ datalayer: { event: 'cancel_bond', product_name: awardSelected.name, product_price: awardSelected.price, index: awardSelected.index }, repeat: true })
    }
    setShowConfirm(!showConfirm)

  }

  const handlerRedemtion = async () => {
    setRedemtionLoading(true)
    let validator = true
    if (awardSelected.twoStepAuth) {
      console.log("Validar aqui si la fecha es correcta")
      validator = false;
    }

    if (awardSelected.canRedem) {
      if (validator) {
        doRedeem(token, awardSelected.price, awardSelected.id).then((data) => {
          setRedemtionLoading(false)
          setShowConfirm(false)

          if (data && data.status === 200 && data.file) {
            setShowSuccessRedemtion(true)
            sendDataLayer({ datalayer: { event: 'redeem_bond', product_name: awardSelected.name, product_price: awardSelected.price, index: awardSelected.index }, repeat: true })

            setTimeout(() => {
              window.location.href = '/GanaConMastercardDebit/mis-redenciones'
            }, 5000)
          } else {
            sendDataLayer({ datalayer: { event: 'exhausted_bond', product_name: awardSelected.name, product_price: awardSelected.price, index: awardSelected.index }, repeat: true })
            setShowErrorRedemtion(true)
          }
        }).catch(error => {
          console.log("ERROR ON REDENTION:", error)
        })
      } else {
        setRedemtionLoading(false)
        setShowConfirm(false)
        setShowErrorRedemtion(true)
        console.log("Validacion no completada.")
      }
    } else {
      setRedemtionLoading(false)
      setShowConfirm(false)
      setShowErrorRedemtion(true)
    }
  }

  return (
    <StateController.Provider
      value={
        {
          setMdalOpen: setMdalOpen,
          context: context,
          staticContent: staticContent,
          pages: pages,
          awardSelected: awardSelected,
          setAwardSelected: setAwardSelected,
          handlerRootModal: handlerModal
        }
      }
    >
      <CustomModalLogin
        title="Datos incorrectos"
        desc="Verifica tu información y vuelve a intentarlo."
        cta="Aceptar"
        icon={false}
        info_title=""
        primaryAction={
          {
            action: closeLoginModal,
            buttonText: 'Aceptar',
            loading: false
          }
        }
        show={notFound} setShowModal={setNotFound}
      />

      <CustomModal
        show={showConfirm}
        setShowModal={setShowConfirm}
        image={awardSelected.image}
        title={`$ ${getPrice(awardSelected.price)}`}
        desc={awardSelected.name}
        twoStepAuth={awardSelected.twoStepAuth}
        primaryAction={
          {
            action: handlerRedemtion,
            buttonText: "Descargar",
            loading: redemtionLoading
          }
        }
      secondaryAction={
        {
          action: handlerModal,
          buttonText: "Cancelar"
        }
      }
      terms={
        {
          termsText: "Términos y condiciones",
          termsLink: "/GanaConMastercardDebit/terminos-y-condiciones"
        }
      }
      />
      <CustomModal
        // title="Ocurrió un error al obtener tu bono. Intentalo de nuevo más tarde. "
        desc={'Lo sentimos, actualmente las gift cards de esta denominación se encuentran agotadas, te avisaremos cuando estén disponibles.'}
        cta="Aceptar"

        show={showErrorRedemtion} setShowModal={setShowErrorRedemtion}
        showErrorRedemtion={showErrorRedemtion}
      />
      <CustomModal
        title="Redención correcta"
        desc={`Tu bono ha sido descargado. Podrás encontrarlo en la sección <strong>${pages ? pages.find((page) => page.sublevel).children.find((page) => page.page.includes('redenciones')).title : 'Redenciones'}</strong>.`}
        cta="Aceptar"
        image="image.png"
        show={showSuccessRedemtion} setShowModal={setShowSuccessRedemtion}
        showSuccessRedemtion={showSuccessRedemtion}
      />

      <div className={`App ${modalOpen ? 'modalOpen' : ''} ${loggedIn ? 'loggedIn' : 'noLoggedIn'}`}>
        <Header
          isLogin={(location.pathname.toLowerCase() === '/ganaconmastercarddebit' || location.pathname.toLowerCase() === '/ganaconmastercarddebit/') ? true : false}
          headerType={1}
          logo={`logo${width > 992 ? '_desktop' : ''}.png`}
          items={pages}
          submenu={{
            type: "nickname",
            default: "A"
          }}
          notifications={
            [
              {
                id: 1,
                title: "Notification",
                content: "html content",
                status: 0
              }
            ]
          }
          loggedIn={loggedIn}
          logOutAction={logOutAction}
        />

        <Routes>
          <Route path="/GanaConMastercardDebit" element={<Login autologin={autologinPreloader} hash={searchParams.get('utm_web')} />} />
          <Route path="/GanaConMastercardDebit/landing" element={<Landing  />} />
          <Route path="/GanaConMastercardDebit/onboarding" element={<Onboarding isLogin={location.pathname.toLowerCase() === '/ganaconmastercarddebit' || location.pathname.toLowerCase() === '/ganaconmastercarddebit/'} privatePage />} />
          <Route path="/GanaConMastercardDebit/terminos-y-condiciones" element={<Terms />} />
          <Route path="/GanaConMastercardDebit/preguntas-frecuentes" element={<Faqs />} />

          {/* Root pages */}
          {pages.map((page, i) => {
            return (
              <Route
                key={i}
                path={`/${page.page}`}
                element={page.component}
              />
            )
          })}

          {/* Child pages */}
          {pages.find((page) => {
            return page.sublevel ? true : false
          })?.children.map((page, i) => {
            return (
              <Route
                key={i}
                path={`/${page.page}`}
                element={page.component}
              />
            )
          })}
          <Route path="/dashboard" element={<Dashboard adminPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        <Footer
          footerType={1}
          footerCardsImg="footer_cards.png"
          secondaryFooterImg="footer_cards.png"
          bankFooterImg="bank-footer.png"
          links={
            [
              {
                link: "/GanaConMastercardDebit/terminos-y-condiciones",
                text: "Términos y condiciones"
              },
              {
                link: "/GanaConMastercardDebit/preguntas-frecuentes",
                text: "Preguntas frecuentes"
              }
            ]
          }
          content="<p>Válido del 5 de agosto al 29 de septiembre de 2024.</p><p>Aplica en compras realizadas fuera del territorio Ecuatoriano de manera presencial o por medios digitales, se incluyen las Apps de transporte, domicilios y streaming de audio y video.</p><p>Esta es una campaña oficial del Banco Guayaquil.</p><p>Tu privacidad es importante para nosotros. Por favor, revisa nuestra política de protección de datos para entender cómo manejamos y protegemos tu información personal.</p>"
          copyright="© Copyright 2024"
        />

      </div>
    </StateController.Provider>
  )
}

const mapState = ({ user: { fetching, loggedIn, token, error } }) => {
  return {
    fetching,
    loggedIn,
    token,
    error
  }
}

export default connect(mapState, { logOutAction, loginShaAction })(App)