import React, { useEffect, useState } from 'react'

import { arrNum } from '../../utils/functions'

function Input({
  type,
  label,
  placeholder,
  termsText,
  termsLink,
  onChange,
  value,
  error,
  inputByCharacter,
  maxlength
}) {
  const [separatedInputs, setSeparatedInputs] = useState([])

  const onChangeSeparated = async (props) => {
    const index = Number(props.target.getAttribute('rel'))
    const cur = JSON.parse(JSON.stringify(separatedInputs))
    const newC = cur.map((item, i) => i === index ? props.target.value : item)
    setSeparatedInputs(newC)
  }

  const onInputSeparated = async (props) => {
    if (props.target.nextElementSibling) {
      props.target.nextElementSibling.focus()
    }
  }

  useEffect(() => {
    const datePick = document.querySelector('#datePickerId')
    if (datePick) {
      const todayDate = new Date('2005-01-01')
      const limitDate = new Date('1900-01-01')
      datePick.max = new Date(todayDate.setDate(todayDate.getDate() - 1)).toISOString().split("T")[0]
      datePick.min = new Date(limitDate.setDate(limitDate.getDate() - 1)).toISOString().split("T")[0]
    }

    if (inputByCharacter && separatedInputs.length === 0) {
      const arr = []
      for (let i = 0; i < arrNum(maxlength).length; i++) arr.push("")
      setSeparatedInputs(arr)
    }
  }, [])

  return (
    <div className="mc-input-container">
      <div className={`mc-input-container__box ${type} ${error ? 'mc-input-container__box--error' : ''}`}>
        <label>{label}</label>
        {type === 'code' && (
          <>
            {inputByCharacter
              ? (
                <div className='mc-input-separated-container'>
                  {arrNum(maxlength).map((i) => (
                    <input
                      key={i}
                      type="text"
                      rel={i}
                      style={{ width: `${(100 / maxlength) - 2}%` }}
                      maxLength={1}
                      onChange={onChangeSeparated}
                      onInput={onInputSeparated}
                      value={separatedInputs[i] ? separatedInputs[i] : ''}
                    />
                  ))}
                </div>
              ) : (
                <input
                  type="text"
                  rel={type}
                  placeholder={placeholder}
                  onChange={onChange}
                  value={value}
                />
              )
            }{error && (
              <div className="mc-input-error">{error}</div>
            )}
            <p className="note">
              <span>Nota:</span> Revisa tu bandeja de entrada o spam para encontrar el correo con el código de ingreso, o contacta al Centro de Ayuda de Banco Guayaquil.
            </p>
          </>
        )}
        {type === 'document' && (
          inputByCharacter ? (
            <div className='mc-input-separated-container'>
              {arrNum(maxlength).map((i) => (
                <input
                  key={i}
                  type="text"
                  rel={i}
                  style={{ width: `${(100 / maxlength) - 2}%` }}
                  maxLength={1}
                  onChange={onChangeSeparated}
                  onInput={onInputSeparated}
                  value={separatedInputs[i] ? separatedInputs[i] : ''}
                />
              ))}
            </div>
          ) : (
            <input
              type="text"
              rel={type}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
            />
          )
        )}
        {type === 'birthdate' && (
          <input
            type="date"
            id="datePickerId"
            rel={type}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
        )}
        {type === 'terms' && (
          <>
            <a href={termsLink} target='_blank' rel="noreferrer">{termsText}</a>
            <input
              type="checkbox"
              rel={type}
              onChange={onChange}
              checked={value}
            />
            <span className="mc-input-checkbox__checkmark"></span>
          </>
        )}
        {type === 'textarea' && (
          <textarea
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          ></textarea>
        )}
        {type === 'emailPhone' && (
          <input
            type="text"
            rel={type}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
        )}
        {error && (
          <div className="mc-input-error">{error}</div>
        )}
      </div>
    </div>
  )
}

export default Input
