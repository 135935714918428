import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom"
import {
  useLocation 
} from 'react-router-dom'

import { StateController } from './../../App'

import VerticalCenter from '../VerticalCenter/VerticalCenter'
import Container from '../Container/Container'
import Icon from '../../components/atoms/Icon'
import Profile from '../../components/atoms/Profile'
import { sendDataLayer } from '../../utils/functions'
import useWidth from '../../hooks/useWidth'


function Header({
  page,
  headerType,
  logo,
  items,
  submenu,
  loggedIn,
  logOutAction,
  isLogin
}) {
  const { context } = useContext(StateController)
  const location = useLocation()
  const [showOthers, setShowOthers] = useState(false)
  const [hasChildren, setHasChildren] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const { width } = useWidth()

  const isSubLevel = (children) => {
    const page = location.pathname.replace('/','')
    const data = children.find((item) => item.page === page)

    return data ? true : false
  }

  const togglerMenu = (e) => {
    
    setShowOthers(!showOthers)

    

    if (e.target.closest('.mc-header-container__nav--item')) {
      let text = e.target.closest('.mc-header-container__nav--item').querySelector('.mc-header-container__nav--button .mc-app-centry > span').innerText
      let link = 'none'

      sendDataLayer({datalayer: {event: 'menu',text: text, link: link},repeat: true})
    }

    if (e.target.closest('a')) {
      let text = e.target.closest('a').querySelector('span').innerText
      let link = e.target.closest('a').getAttribute('href')
      sendDataLayer({datalayer: {event: 'menu',text: text, link: link},repeat: true})
    }
  
  }

  const toogleSumMenu = (e) => {
    setShowMenu(!showMenu)
    let text = (
      e.target.closest('.mc-header-container__nav--item') && 
      e.target.closest('.mc-header-container__nav--item').querySelector('.mc-header-container__nav--button .mc-app-centry > span')
    ) ? e.target.closest('.mc-header-container__nav--item').querySelector('.mc-header-container__nav--button .mc-app-centry > span') : ''
    let link = 'none'
    sendDataLayer({datalayer: {event: 'menu',text: text, link: link},repeat: true})

  }

  const closeSumMenu = () => {
    setShowMenu(false)
  }

  const addDataLayer = (e) => {
    if (e.target.closest('.mc-header-container__nav--item')) {
      let text = e.target.closest('.mc-header-container__nav--item').querySelector('a .mc-app-centry > span').innerText
      let link = e.target.closest('.mc-header-container__nav--item').querySelector('a').getAttribute('href')
      sendDataLayer({datalayer: {event: 'menu',text: text, link: link},repeat: true})
    }
    

  }

  useEffect(() => {
    const child = items.find((item) => item.sublevel)

    if (child) {
      setHasChildren(child)
    }
  }, [items])

  useEffect(() => {

    window.addEventListener('click', ({ target }) => {
      const window = target.closest('.mc-header-container__nav--extra')
      const button = target.closest('.mc-header-container__nav--button')

      if (window === null && button === null) setShowOthers(false)
    })
  }, [])

  const closeSession = (e) => {
    console.log("Salir")
    logOutAction()
    setShowOthers(false)
    /*
    let text = (
      e.target.closest('.mc-header-container__nav--item') && 
      e.target.closest('.mc-header-container__nav--item').querySelector('.mc-header-container__nav--button .mc-app-centry > span')
    ) ? e.target.closest('.mc-header-container__nav--item').querySelector('.mc-header-container__nav--button .mc-app-centry > span').innerText : ''
    */
   
    let link = 'none'
    
    sendDataLayer({datalayer: {event: 'menu',text: e.target.textContent, link: link},repeat: true})

  }

  return (
    <div 
      className={`
      mc-header-container mc-header-container-type-${headerType} 
      mc-header-container-page-${page !== '' ? page ? page : 'login' : 'login' } 
      ${showMenu ? 'show-menu' : ''}`
    }>
      <div className='mc-header-container__menu'>
        <Container fullHeight={true}>
            
          <a className="mc-header-container__logo" href={loggedIn ? "/GanaConMastercardDebit/progreso" : "/GanaConMastercardDebit"}>
            <VerticalCenter>
              <img src={`/assets/images/${logo}`} alt="Logo Banco de Bogotá" />
            </VerticalCenter>
          </a>

          {headerType === 1 && loggedIn &&
            <div className="mc-header-container__mobile--close" onClick={closeSession}>
              <img src="/assets/images/close-desktop.png" />
            </div>
          }

          {headerType === 4 &&
            <div className="mc-header-container__mobile--submenu" onClick={toogleSumMenu}>
              <VerticalCenter>
                <Icon name="menu" />
              </VerticalCenter>
            </div>
          }

          <nav className="mc-header-container__nav">
            {headerType === 4 &&
              <div
                className="mc-header-container__mobile--overlay" 
                onClick={closeSumMenu}
              >
              </div>
            }
            {headerType === 4 &&
              <div className="mc-header-container__nav--close" onClick={closeSumMenu}>
                <Icon name="close" />
              </div>
            }

            {loggedIn
              ? <ul>
                  {items.filter((item) => {
                    if (item?.component?.props?.privatePage || item?.sublevel) {
                      if (width > 992) {
                        return true
                      } else {
                        if (item?.onlyDesktop) {
                          return false
                        } else {
                          return true
                        }
                      }
  
                    } else {
                      return false
                    }
                  }).map((item, index) => {
                    return (
                      item.sublevel
                        ? <li 
                            className={`mc-header-container__nav--item slow_ani ${item.page} ${ ((showOthers) || (isSubLevel(item.children))) ? 'mc-header-container__nav--item-active' : ''}`}
                            onClick={togglerMenu}
                            key={index}
                          >
                            <div className="mc-header-container__nav--button">
                              <VerticalCenter>
                                <span>
                                  {item.icon &&
                                    <Icon name={item.icon} />
                                  }
                                  {item.title} 
                                </span>
                              </VerticalCenter>
                            </div>
                          </li>
                        : <li 
                            className={`mc-header-container__nav--item item-${item.page} slow_ani ${location.pathname.replace('/','') === item.page && !showOthers ? 'mc-header-container__nav--item-active' : ''}`} 
                            onClick={addDataLayer}
                            key={index}
                          >
                            <Link to={`/${item.page}`}>
                              <VerticalCenter>
                                <span>
                                  {item.icon &&
                                    <Icon name={item.icon} />
                                  }
                                  {item.title}
                                </span>
                              </VerticalCenter>
                            </Link>
                          </li>
                    )
                  })}
                </ul>
              : <ul>

                  {items.filter((item) => {
                    if (item?.component?.props?.privatePage || item?.sublevel) {
                      return false
                    } else {
                      return true
                    }
                  }).map((item, index) => {
                    return (
                      <li 
                        className={`mc-header-container__nav--item item-${item.page} slow_ani ${location.pathname.replace('/','') === item.page && !showOthers ? 'mc-header-container__nav--item-active' : ''}`} 
                        onClick={addDataLayer}
                        key={index}
                      >
                        <Link to={`/${item.page}`}>
                          <VerticalCenter>
                            <span>
                              {item.icon &&
                                <Icon name={item.icon} />
                              }
                              {item.title}
                            </span>
                          </VerticalCenter>
                        </Link>
                      </li>
                    )
                  })}

                  <li className={`mc-header-container__nav--item-active slow_ani`}>
                    <Link to="/" className='mc-header-link'>
                      <span>Ingresar</span>
                    </Link>
                  </li>
                </ul>
            }

            {hasChildren &&
              <div className={`mc-header-container__nav--extra slow_ani ${showOthers ? 'opened' : ''}`}>
                {hasChildren.children.map((item, index) => {
                  return (
                    <Link to={`/${item.page}`} key={index} onClick={togglerMenu}>
                      <span>
                        {item.icon &&
                          <Icon name={item.icon} />
                        }
                        {item.title}
                      </span>
                    </Link>
                  )
                })}
                <div className="mc-header-container__nav--close" onClick={closeSession}>
                  Salir
                </div>
              </div>
            }
          </nav>
        </Container>
      </div>

      {(submenu && !isLogin ) &&
        <div className={`mc-header-container__submenu myclass  ${!loggedIn ? 'not-login' : ''}`}>
          <Container fullHeight={true}>
            <VerticalCenter>
              {submenu.type === 'labels' &&
                <>
                  {submenu.icon && <Icon name={submenu.icon} />}
                  <div dangerouslySetInnerHTML={{ __html: submenu.content }} />
                </>
              }
              {submenu.type === 'nickname' && loggedIn && context?.user?.nickname &&
                <Profile
                  nickname={context.user.nickname}
                  defaultLetter={submenu.default}
                />
              }
            </VerticalCenter>
          </Container>
        </div>
      }
    </div>
  )
}

export default Header