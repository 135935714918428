import React, { useEffect, useState, useContext } from 'react'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'

import { StateController } from './../../App'
import Container from '../../layouts/Container/Container'
import PrimaryBanner from '../molecules/PrimaryBanner'
import Preloader from '../atoms/Preloader'

function Terms() {
  const { staticContent } = useContext(StateController)
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  )
  const [termsHTML, setTermsHTML] = useState(false)

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (staticContent && staticContent?.terms && staticContent.terms[0]?.content) {
      console.log('json',JSON.parse(staticContent?.terms[0]?.content))
      const json = JSON.parse(staticContent?.terms[0]?.content)
      if (json && json?.json && json?.json?.blocks) {
        setTermsHTML(true)

        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(json?.json)
          )
        )
      }
    }
  }, [staticContent])
  console.log('staticContent',staticContent)
  return (
    <div className='mc-page terms slow_ani'>

      <PrimaryBanner
        goBack
        title={<>Términos y condiciones</>}
        description={<>Texto descriptivo que se usa para secciones que necesiten una bienvenida o contexto</>}    
      />
      
      <Container className={'spacing'}>
        

        {termsHTML
          ? <div className='mc-static-content'>
              <div dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) }} />
            </div>
          : <Preloader
              type="section"
              invert
            />
        }
      </Container>

    </div>
  )
}
export default Terms