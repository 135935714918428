import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 998);

  const handleGoBack = (event) => {
    event.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 998);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='mc-page not-found slow_ani'>
      <div className='before-page-container'>
        <div><img src="/assets/images/backarrow.png" alt="" /></div>
        <div><a href="" onClick={handleGoBack}>Volver a la página anterior</a></div>
      </div>
      <div className="notFound-container">
        <div className='text-container'>
          <div className='title-container'>
            {isMobile ? (
              <div className='error-container-text'>
                <h2>
                  <span>Error 404</span>
                </h2>
              </div>
            ) : (
              <>
                <div className='error-container-text'>
                  <h2>
                    <span>Error</span>
                  </h2>
                </div>
                <div className='error-container-num'>
                  <h2>404</h2>
                </div>
              </>
            )}
          </div>
          <div className='paragraph'>
            <p>Estamos presentando algunas fallas técnicas. <span>No te preocupes, estamos trabajando para solucionarlas y que puedas acceder sin problemas.</span> Tómate un descanso y vuelve más tarde. ¡Gracias por tu paciencia!</p>
          </div>
        </div>
        <div className='img-container'>
          <img className='img-error' src="/assets/images/404.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export default NotFound;
