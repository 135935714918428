import React, { useContext, useEffect, useState } from 'react';
import { StateController } from './../../App';
import Container from '../../layouts/Container/Container';
// import PrimaryBanner from '../molecules/PrimaryBanner';
import AwardsGallery from '../organisms/AwardsGallery';
import Preloader from '../atoms/Preloader';
import { getMaximumBlock } from '../../utils/functions';
import BannerImage from '../molecules/BannerImage';
import { sendDataLayer } from '../../utils/functions';
import { Link } from 'react-router-dom';

function Awards() {
  const [awards, setAwards] = useState(false);
  const [canRedem, setCanRedem] = useState(false);
  const { context } = useContext(StateController);
  const segment = context?.user?.segment ? context.user.segment : '0'

  useEffect(() => {
    if (context && context.awards) {
      setAwards(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].awards
      );
      setCanRedem(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].canRedem
      );
    }
  }, [context]);

  const dataLayerAllied = (e, content, link) => {
    sendDataLayer({
      datalayer: {
        event: 'alliances',
        text: content,
        link: link
      },
      repeat: true
    });
  }

  return (
    <>
      <div className='mc-page awards slow_ani'>
        {/* <PrimaryBanner image="banner.png" /> */}
        <Container>
          <div className='mc-title-award-container'>
            <div className='mc-title-award-container--title'>
              <h1>Catálogo de Gift Cards digitales</h1>
            </div>
            <div>
              <p>El botón de canjear estará disponible cuando ganes <Link className='link' to={'/GanaConMastercardDebit/progreso'}>tu meta.</Link></p>
            </div>
          </div>
        </Container>

        <Container>
          {awards ? (
            <AwardsGallery
              awardBoxType={2}
              itemsPerLine={3}
              limit={false}
              limitButtonText="Ver más comercios"
              showStock={true}
              list={awards}
              steps={false}
              canRedem={canRedem}
              // twoStepAuth={true}
              redemtionText="Canjear"
              termText="Términos y condiciones"
              termLink="/GanaConMastercardDebit/terminos-y-condiciones"
            />
          ) : (
            <Preloader type="section" invert />
          )}
        </Container>
      </div>

      {segment === '1' ? (
        <BannerImage
          textLink={'*Aplican Términos y Condiciones.'}
          link={'/GanaConMastercardDebit/terminos-y-condiciones'}
          className='container'
        >
          <h2>
            <span>Supera tu meta</span> y participa por un increíble viaje para dos a Río de Janeiro, Brasil.
          </h2>
        </BannerImage>
      ) : (
        <BannerImage
          textLink={'*Aplican Términos y Condiciones.'}
          link={'/GanaConMastercardDebit/terminos-y-condiciones'}
          className='container-img-2'
        >
          <h2>¿Te encanta Apple?</h2>
          <p className='paragraph'>Participa por un kit de productos Apple, que incluye un MacBook Air M2, un iPhone 15 Pro y un Apple Watch Serie 9</p>
        </BannerImage>
      )}


      <div className='mc-page__progress--extra'>
        <Container>
          <div className='mc-page__progress--extra__t1'>
            Aprovecha estas alianzas que <strong>te ayudarán a cumplir tu meta</strong>
          </div>
          <div className='mc-page__progress--extra__t2'>
            <div className='mc-page__progress--extra__t2--item' onClick={(e) => dataLayerAllied(e, "Carl's JR", '/premios')}>
              <div className='mc-page__progress--extra__t2--item__image border_ajuste'>
                <img src="/assets/images/combo_classic_border.png" alt="" />
              </div>
              <div className='mc-page__progress--extra__t2--item__labels'>
                <h3>Carl{`'`}s JR</h3>
                <p>Con tus tarjetas Mastercard disfruta del <strong>Classic Combo por solo $4,90</strong>. <span><br /> <br />(Precio normal $7,30)</span></p>
              </div>
            </div>
            <div className='mc-page__progress--extra__t2--item' onClick={(e) => dataLayerAllied(e, "Carl's JR", '/premios')}>
              <div className='mc-page__progress--extra__t2--item__image border_ajuste'>
                <img src="/assets/images/super_duo_border.png" alt="" />
              </div>
              <div className='mc-page__progress--extra__t2--item__labels'>
                <h3>Carl{`'`}s JR</h3>
                <p><strong>Disfruta del Super Duo a $9,90</strong> usando tus tarjetas Mastercard. <span> <br /> <br />(Precio normal $11,90)</span></p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Awards;
