import React from 'react'

const BannerImage = ({ children, textLink, link, className }) => {
    return (
        <div className='mc-bannerImage'>
            <div className={className}>
                <div className='text'>
                    <div>
                        {children}
                    </div>
                    <div className='link'>
                        <a href={link} style={{color : '#FDF2F8'}}>{textLink}</a>
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    )
}

export default BannerImage
