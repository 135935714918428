import React, { useEffect, useContext, useState } from 'react'
// import { Link } from "react-router-dom"
import { StateController } from './../../App'

import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Input from '../atoms/Input'
import Button from '../atoms/Button'
import Icon from '../atoms/Icon'
import { sendDataLayer } from '../../utils/functions'

function CustomModal({
  show,
  setShowModal,
  primaryAction,
  secondaryAction,
  terms,
  image,
  title,
  desc,
  cta,
  twoStepAuth,
  showErrorRedemtion,
  showSuccessRedemtion,
  redemtion,
  position,
  isAwardTerms
}) {
  const { setMdalOpen } = useContext(StateController)
  const [twoStepAuthStatus, setTwoStepAuthStatus] = useState(false)
  const [expDate, setExpDate] = useState('')

  const [errors, setErrors] = useState({
    expDate: false
  })

  useEffect(() => {

    setMdalOpen(show ? true : false)
  }, [show])

  const close = () => {
    if (redemtion) {
      sendDataLayer({datalayer: {event: 'cancel_bond',product_name: desc ,product_price: title, index: position},repeat: true})
    }
    if (show && !showSuccessRedemtion) {
      sendDataLayer({datalayer: {event: 'cancel_bond',product_name: desc ,product_price: title, index: position},repeat: true})
    }
    setShowModal(false)
  }

  const handleChange = async (e) => {    
    const element = e.target.getAttribute('rel') 

    if (element === 'birthdate') {
      setExpDate(e.target.value)
      setErrors({ ...errors, expDate: false})

      setTwoStepAuthStatus(true)
    }
  }
  
  return (
    <div className={`mc-custom-modal ${show && 'mc-custom-modal__opened'} slow_ani`}>
      <div className="mc-custom-modal__overlay slow_ani" onClick={close}></div>
      <div className={`mc-custom-modal__center ${isAwardTerms ? 'extended' : ''}`}>
        <VerticalCenter>
          <div className="mc-custom-modal__center--window slow_ani">
            <div className="mc-custom-modal__close" onClick={close}>
              <Icon name="close" />
            </div>
                        
            <div className={`modal_header_content ${showErrorRedemtion ? 'modalError' : ''}`}>
              {(image && !showErrorRedemtion && !showSuccessRedemtion) &&
                <div
                className="mc-custom-modal__center--window__image"
                style={{"backgroundImage": `url(/assets/images/${image})`}}
                >
                </div>
              }
              {!isAwardTerms &&
                <div className="header_modal_title">
                  <div className="mc-custom-modal__center--window__desc">
                    <div dangerouslySetInnerHTML={{ __html: desc }} />
                  </div>
                  {
                    (!showErrorRedemtion && !showSuccessRedemtion) &&
                    <div className='info_title'>Gift Card</div>

                  }
                  {
                    (!showErrorRedemtion && !showSuccessRedemtion) &&
                    <div className="mc-custom-modal__center--window__title"><span>USD {title}</span></div>

                  }
                </div>
              }
              {isAwardTerms &&
                <div className="header_modal_title">
                  <div className="mc-custom-modal__center--window__desc">
                    {title}
                  </div>
                </div>
              }

            </div> 

            {isAwardTerms &&
              <div>
                <div dangerouslySetInnerHTML={{ __html: desc }} />
              </div>
            }           

            {twoStepAuth &&
              <div className="mc-custom-modal__center--window__auth">
                <h4 className='title_auth'>Validación de seguridad</h4>
                <p className='text_auth'>Para descargar su bono ingrese la siguiente información:</p>

                <Input 
                  type={'birthdate'}
                  label={'Fecha de expedición de su cédula'}
                  placeholder={'DD/MM/AAAA'}
                  onChange={handleChange}
                  inputByCharacter={false}
                  maxlength={10}
                  value={expDate}
                  error={errors.expDate}
                />
              </div>
            }

            <div className="mc-custom-modal__center--window__actions">
              <Button
                type="primary"
                text={primaryAction ? primaryAction.buttonText : cta}
                loading={primaryAction ? primaryAction.loading : false}
                status={twoStepAuth ? twoStepAuthStatus === true ? `active` : 'inactive' : 'active'}
                onClick={primaryAction ? primaryAction.action : close}
                icon={!isAwardTerms ?'download.png' : false}
                showErrorRedemtion={showErrorRedemtion}
              />
              {secondaryAction &&
                <Button
                  type="secondary"
                  text={secondaryAction.buttonText}
                  loading={false}
                  status="active"
                  onClick={secondaryAction.action}                 
                />
              }
            </div>
            {terms &&
              <div className="mc-custom-modal__center--window__terms">
                {/* <Link to={terms.termsLink} target="_blank">
                  {terms.termsText}
                </Link> */}
              </div>
            }
            {
              !isAwardTerms && (!showErrorRedemtion && !showSuccessRedemtion) &&
              <div className="tyc_copy">
                Los bonos digitales están sujetos a términos y condiciones de cada comercio aliado, los cuales se especifican en el bono digital que se redime y descarga en la plataforma.
              </div>
            }

          </div>
        </VerticalCenter>
      </div>
    </div>
  )
}

export default CustomModal