import React from 'react'

import Icon from './Icon'

function ProgressBar({
  title,
  percent, 
  label,
  icon,
  middle,
  dashboard
}) {
  
  return (
    <div className={`mc-progressBar-container ${title ? 'has-title' : ''} ${middle ? 'col-2' : 'col-1'}`}>
      {title &&
        <div className='mc-progressBar-title'>
          {title}
        </div>
      }
      {dashboard &&
        <span className='mc-progressBar-label'>{`${percent}%`} {dashboard}</span>
      }
      <img src="/assets/images/goal_progress.png" className="goal" />
      <div className="mc-progressBar-progress" style={{"width": `${(percent) > 100 ? (100) : (percent < 8 ? 8 : percent)}%`}}>
        <img src="/assets/images/card_progress.png" alt="" />
      </div>
      <div className={`mc-progressBar-percent ${percent > 52 ? 'white' : ''}`}>
        {icon &&
          <Icon name={icon} />
        }
        {label && label}
      </div>
    </div>
  )
}

export default ProgressBar