import React from 'react'
import HeaderLanding from '../../layouts/Header/HeaderLanding'
// // import FooterLanding from '../../layouts/Footer/FooterLanding'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import useWidth from '../../hooks/useWidth';

import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

const Landing = () => {
    const { width } = useWidth();

    // useEffect(() => {
    //     if (window.location.pathname.includes("landing")) {
    //         document.body.classList.add("landing-page");
    //     } else {
    //         document.body.classList.remove("landing-page");
    //     }
    // }, []);

    console.log(width, "este es el width que me mato");

    return (
        <div className='mc-container-landing'>
            <HeaderLanding />
            <div className='__page'>
                <div className='__text'>
                    <div className="__container">
                        <div>
                            <h1>Mecánica</h1>
                        </div>
                        <p>Sigue estos pasos y gana con tu Mastercard Debit:</p>
                    </div>
                </div>
                <div className='__cards-container'>
                    {width < 992 ? (<div className='__cards-container-vertical'>
                        <div className='__card'>
                            <div className='__img'>
                                <img src='/assets/images/img_card_1.png' alt="" />
                            </div>
                            <div className='__text-card'>
                                <p><strong>1.</strong> Conoce tu meta en <br />facturación y las categorías <br />que te llevarán a alcanzarla.</p>
                            </div>
                        </div>
                        <div className='__card'>
                            <div className='__img'>
                                <img src='/assets/images/img_card_2_2.png' alt="" />
                            </div>
                            <div className='__text-card'>
                                <p><strong>2.</strong> Usa tu Mastercard Debit en <br />todas tus compras para <br />alcanzar la meta.</p>
                            </div>
                        </div>
                        <div className='__card'>
                            <div className='__img'>
                                <img src='/assets/images/img_card_3.png' alt="" />
                            </div>
                            <div className='__text-card'>
                                <p><strong>3.</strong> Redime la Gift Card digital <br />que más te guste y apúntale a <br />ganar el premio mayor.</p>
                            </div>
                        </div>
                        <div className='__card'>
                            <div className='__img'>
                                <img src='/assets/images/img_card_4_1.png' alt="" />
                            </div>
                            <div className='__text-card'>
                                <p><strong>4.</strong>  Mientras más veces alcances tu meta mas probabilidades tendrás de participar por un increíble viaje a Río de Janeiro o un Kit de Apple.<br /></p>
                                {/* <p><strong>4.</strong> ¡El premio mayor puede ser tuyo!<br /></p> */}
                                {/* <h6>Alcanza tu meta las veces que quieras y podrás participar por un increíble viaje para dos a Río de Janeiro o un kit de Apple. <br />(Aplican términos y condiciones)</h6> */}
                            </div>
                        </div>
                    </div>
                    ) : (<Swiper
                        ccssMode={true}
                        navigation={true}
                        pagination={{ clickable: true }}
                        mousewheel={true}
                        keyboard={true}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                        className='__swiper'
                        spaceBetween={1}
                        slidesPerView={3.6}
                        loop={false}
                    >
                        <SwiperSlide>
                            <div className='__card'>
                                <div className='__img'>
                                    <img src='/assets/images/img_card_1.png' alt="" />
                                </div>
                                <div className='__text-card'>
                                    <p><strong>1.</strong> Conoce tu meta en <br />facturación y las categorías <br />que te llevarán a alcanzarla.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='__card'>
                                <div className='__img'>
                                    <img src='/assets/images/img_card_2_2.png' alt="" />
                                </div>
                                <div className='__text-card'>
                                    <p><strong>2.</strong> Usa tu Mastercard Debit en <br />todas tus compras para <br />alcanzar la meta.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='__card'>
                                <div className='__img'>
                                    <img src='/assets/images/img_card_3.png' alt="" />
                                </div>
                                <div className='__text-card'>
                                    <p><strong>3.</strong> Redime la Gift Card digital <br />que más te guste y apúntale a <br />ganar el premio mayor.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='__card'>
                                <div className='__img'>
                                    <img src='/assets/images/img_card_4_1.png' alt="" />
                                </div>
                                <div className='__text-card'>
                                    <p><strong>4.</strong> Mientras más veces alcances tu meta mas probabilidades tendrás de participar por un increíble viaje a Río de Janeiro o un Kit de Apple.<br /></p>
                                    {/* <p><strong>4.</strong> ¡El premio mayor puede ser tuyo!<br /></p> */}
                                    {/* <h6>Alcanza tu meta las veces que quieras y podrás participar por un increíble viaje para dos a Río de Janeiro o un kit de Apple. <br /> (Aplican términos y condiciones)</h6> */}
                                </div>
                            </div>
                        </SwiperSlide>
                        <div className="swiper-pagination"></div>
                    </Swiper>
                    )}
                </div>
            </div>
            {/* <FooterLanding /> */}
        </div>
    )
}

export default Landing