import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const HeaderLanding = () => {
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 998);
        };
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isLanding = location.pathname.includes('/landing');
    const isOnboarding = location.pathname.includes('/onboarding');

    const handleLogout = () => {
        localStorage.removeItem('storage'); 
        window.location.href = '/'
    }

    return (
        <>
            {isMobile ? (
                <div
                    className='mc_container_header_landing'
                >
                    <div className='__main' style={!isLanding ? { backgroundColor: '#FDF2F8' } : {}}>
                        <div className='__logo'>
                            <figure>
                                <a href="#">
                                    <img className='img-mobile-1' src={isLanding ? '/assets/images/guayaquil.png' : '/assets/images/guayaquil_logo.png'} alt="" />
                                </a>
                            </figure>
                        </div>
                        {
                            isOnboarding ? (<div className='__text'>
                                <figure>
                                    <a href="#">
                                        <img src={isLanding ? '/assets/images/mc.png' : '/assets/images/mc_color.png'} alt="" />
                                    </a>
                                </figure>
                            </div>) : (
                                <div className='__text'>
                                    <figure>
                                        <a href="#">
                                            <img style={{ left: '0' }} src={isLanding ? '/assets/images/mc.png' : '/assets/images/mc_color.png'} alt="" />
                                        </a>
                                    </figure>
                                </div>
                            )
                        }

                        {isOnboarding ? (
                            <div className="close-btn" onClick={handleLogout}>
                                <img src="/assets/images/close-desktop.png" alt="Close" />
                            </div>
                        ) : (
                            <div className="close-btn" style={{ display: 'none' }}>
                                <img src="/assets/images/close-desktop.png" alt="Close" />
                            </div>
                        )}

                    </div>

                </div>
            ) : (
                <div
                    className='mc_container_header_landing'

                >
                    <div className='__main' style={!isLanding ? { backgroundColor: '#FDF2F8' } : {}}>
                        {isLanding ? (<div className='__logo'>
                            <figure>
                                <a href="#">
                                    <img src={isLanding ? '/assets/images/logos2.png' : '/assets/images/guayaquil_logo.png'} alt="" />
                                </a>
                            </figure>
                        </div>) : (
                            <div className='__logo'>
                                <div className='container-logo-mc'>
                                    <figure className='container-img-1'>
                                        <a href="#">
                                            <img className='img-1' src={isLanding ? '/assets/images/logos2.png' : '/assets/images/guayaquil_logo.png'} alt="" />
                                        </a>
                                    </figure>
                                    <figure>
                                        <a href="#">
                                            <img className='img-2' src={isLanding ? '/assets/images/logos2.png' : '/assets/images/mc_color.png'} alt="" />
                                        </a>
                                    </figure>
                                </div>
                                {isOnboarding ? (
                                    <div className="close-btn" onClick={handleLogout}>
                                        <img src="/assets/images/close-desktop.png" alt="Close" />
                                    </div>
                                ) : (
                                    <div className="close-btn" style={{ display: 'none' }}>
                                        <img src="/assets/images/close-desktop.png" alt="Close" />
                                    </div>
                                )}
                            </div>)}
                        <div className='__text'></div>
                    </div>
                </div>
            )}
        </>
    );
}

export default HeaderLanding;
