import React, { useContext, useEffect, useState } from 'react'

import Container from '../../layouts/Container/Container'
import AwardsGallery from '../organisms/AwardsGallery'
import Preloader from '../atoms/Preloader'
import { StateController } from './../../App'
import useWidth from '../../hooks/useWidth'
import BannerImage from '../molecules/BannerImage'
import ConfimDownload from '../atoms/ConfimDownload'

function Redemptions() {
  const { width } = useWidth()
  const { context } = useContext(StateController)
  const [redemtions, setRedemtions] = useState(false)
  const segment = context?.user?.segment ? context.user.segment : '0'
  const [showConfirm, setShowConfirm] = useState(false);
  

  useEffect(() => {
    if (context && context.redemtions) {
      console.log("Context redemtions:", context)
      setRedemtions(context.redemtions)
    }
  }, [context])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='mc-page redemptions slow_ani'>

      <div className={`mc-page--banner ${redemtions.length === 0 ? 'empty' : ''}`}>
        <Container> 
          {
            showConfirm &&
            <ConfimDownload showConfirm={showConfirm} />
          }       
          <div className='mc-page--banner__content'>
            {redemtions && redemtions[0] &&
              <div className='mc-page--banner__content--title'>
                ¡Esta es tu  Gift Card!
              </div>
            }

            {width > 992 &&
              <div className='mc-page--banner__content--image'>
                <img src={`/assets/images/redemtinos_banner.png`} alt="" />
              </div>
            }

            <div className={`mc-page--banner__content--items ${redemtions.length === 0 ? 'empty' : ''}`}>
              {redemtions
                ? <AwardsGallery
                  awardBoxType={2}
                  itemsPerLine={3}
                  list={redemtions}
                  showStock={false}
                  canRedem={true}
                  download={true}
                  redemtionText="Volver a descargar"
                  termText="Términos y condiciones"
                  termLink="/GanaConMastercardDebit/terminos-y-condiciones"
                  setShowConfirm={setShowConfirm}
                />
                : <Preloader
                  type="section"
                  invert
                />
              }
            </div>
          </div>
        </Container>
      </div>


      <div className='mc-page--extra'>
        {segment === '1' ? (
          <BannerImage
            textLink={'*Aplican Términos y Condiciones.'}
            link={'/GanaConMastercardDebit/terminos-y-condiciones'}
            className='container'
          >
            <h2>
              <span>Supera tu meta</span> y participa por un increíble viaje para dos a Río de Janeiro, Brasil.
            </h2>
          </BannerImage>
        ) : (
          <BannerImage
            textLink={'*Aplican Términos y Condiciones.'}
            link={'/GanaConMastercardDebit/terminos-y-condiciones'}
            className='container-img-2'
          >
            <h2>¿Te encanta Apple?</h2>
            <p className='paragraph'>Participa por un kit de productos Apple, que incluye un MacBook Air M2, un iPhone 15 Pro y un Apple Watch Serie 9</p>
          </BannerImage>
        )}
      </div>
    </div>
  )
}
export default Redemptions